<template lang="pug">
v-footer(align="center")
  .footer_sub.w-100
    v-row.w-100.mx-0
      v-col(cols="12", sm="6")
        h2.main_title.mt-0 
          span Follow Us
        .social.mt-4.d-flex.justify-center
          font-awesome-icon(
            :icon="['fab', 'instagram-square']",
            @click="openUrl('https://www.instagram.com/gc.basket/')",
            style="cursor: pointer"
          )
          font-awesome-icon(
            :icon="['fab', 'facebook-square']",
            @click="openUrl('https://www.facebook.com/GCbasket-108430528343512')",
            style="cursor: pointer"
          )
        p.mt-4.ig @gc.basket
      v-col.email_col.position-relative(cols="12", sm="6")
        h2.main_title.mt-0 
          span Email
        .social.mt-4
          a(href="mailto: gc.basket.co@gmail.com")
            font-awesome-icon(:icon="['fas', 'envelope-open-text']")
        p.mt-4.email gc.basket.co@gmail.com

  .footer
    h5 Copyright © 2022 競晰股份有限公司
</template>

<script>
export default {
  name: "Footer",

  methods: {
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/footer";
</style>
