<template lang="pug">
v-app(:height="$store.state.screenHeight", v-resize="onResize")
  navbar
  v-main
    transition(name="page", mode="out-in")
      router-view
</template>

<script>
import "@/assets/style/main.scss";
import "@/assets/style/components.scss";
import Navbar from "@/components/common/Navbar";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {};
  },
  mounted() {
    this.onResize();
  },
  methods: {
    onResize() {
      this.$store.commit("setScreenHeight", window.innerHeight);
      this.getNavbarHeight();
    },
    getNavbarHeight() {
      let screenWidth = document.documentElement.clientWidth;
      if (screenWidth > 959) {
        this.$store.commit("setNavbarHeight", 72);
      } else {
        this.$store.commit("setNavbarHeight", 48);
      }
    },
  },
};
</script>
