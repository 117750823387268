import { login, getUserInfo } from "@/api/user";
import {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
} from "@/utils/auth";
import { resetRouter } from "@/router";

const user = {
  namespaced: true,
  state: {
    accessToken: getAccessToken(),
    userEmail: "",
    name: "",
    nickname: "",
    photo: "",
    isPhoneVerified: false,
    isProfileComplete: false,
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, token) => {
      state.accessToken = token;
    },
    SET_USER_EMAIL: (state, email) => {
      state.userEmail = email;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname;
    },
    SET_PHOTO: (state, photo) => {
      state.photo = photo;
    },
    SET_IS_PHONE_VERIFIED: (state, isPhoneVerified) => {
      state.isPhoneVerified = isPhoneVerified;
    },
    SET_IS_PROFILE_COMPLETE: (state, isProfileComplete) => {
      state.isProfileComplete = isProfileComplete;
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        login(data)
          .then((response) => {
            commit("SET_ACCESS_TOKEN", response.data.token);
            setAccessToken(response.data.token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((response) => {
            commit("SET_USER_EMAIL", response.data.email);
            commit("SET_NAME", response.data.name);
            commit("SET_NICKNAME", response.data.nickname);
            commit("SET_PHOTO", response.data.photo);
            commit("SET_IS_PHONE_VERIFIED", response.data.is_phone_verified);
            commit(
              "SET_IS_PROFILE_COMPLETE",
              response.data.is_profile_complete
            );
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout({ commit }) {
      removeAccessToken();
      resetRouter();
      commit("SET_ACCESS_TOKEN", "");
    },
  },
  getters: {},
};

export default user;
