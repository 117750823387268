import request from "@/utils/request";

export function getLeagueList() {
  return request({
    url: "/league_list/",
    method: "get",
  });
}

export function getFavoriteLeagueData() {
  return request({
    url: "/favorite_league_data/",
    method: "get"
  });
}
