import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import League from "./league";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/table",
    name: "Table",
    component: () => import("@/views/Table/index.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/User/Register/index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/User/Login/index.vue"),
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: () => import("@/views/User/ForgetPassword/index.vue"),
  },
  {
    path: "/reset_password",
    name: "ForgetPasswordConfirm",
    component: () => import("@/views/User/ForgetPasswordConfirm/index.vue"),
  },
  {
    path: "/register/verify",
    name: "Verify",
    component: () => import("@/views/User/Verify/index.vue"),
  },
  {
    path: "/verifymobile",
    name: "VerifyMobile",
    component: () => import("@/views/User/VerifyMobile/index.vue"),
  },
  {
    path: "/membercenter",
    name: "MemberCenter",
    component: () => import("@/views/User/MemberCenter/index.vue"),
  },
  {
    path: "/fantasy",
    name: "Fantasy",
    component: () => import("@/views/Fantasy/index.vue"),
    redirect: {
      name: "Overview",
    },
    children: [
      {
        path: "myteam",
        name: "MyTeam",
        component: () => import("@/views/Fantasy/MyTeam/index.vue"),
      },
      {
        path: "createteam",
        name: "CreateTeam",
        component: () => import("@/views/Fantasy/CreateTeam/index.vue"),
      },
      {
        path: "overview",
        name: "Overview",
        component: () => import("@/views/Fantasy/Overview/index.vue"),
      },
      {
        path: "draft",
        name: "Draft",
        component: () => import("@/views/Fantasy/Draft/index.vue"),
      },
      {
        path: "myleague",
        name: "MyLeague",
        component: () => import("@/views/Fantasy/MyLeague/index.vue"),
      },
    ],
  },
  {
    path: "/fantasy/maintain",
    name: "Maintain",
    component: () => import("@/views/Fantasy/Maintain/index.vue"),
  },
];
routes.push(...League);

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

const router = createRouter();

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
