import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import SvgIcon from "@/components/common/SvgIcon";
import { getAccessToken } from "@/utils/auth";

import VueAwesomeSwiper from "vue-awesome-swiper";
import SwiperClass, { Pagination, Autoplay, Navigation } from "swiper";

// import swiper module styles
import "swiper/swiper-bundle.css";

// use swiper modules
SwiperClass.use([Pagination, Autoplay, Navigation]);
Vue.use(VueAwesomeSwiper);

import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faInstagramSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

import {
  faChartBar,
  faCalendarAlt,
  faCalendar,
  faAngleDoubleDown,
  faSort,
  faCaretUp,
  faCaretDown,
  faThLarge,
  faStar,
  faBasketballBall,
  faSign,
  faTrophy,
  faBookOpen,
  faHeadset,
  faFlag,
  faHandshake,
  faLongArrowAltRight,
  faEnvelopeOpenText,
  faSearch,
  faMagic,
  faChevronDown,
  faExclamationTriangle,
  faTools,
  faCheck,
  faVolumeUp,
  faStream,
  faShareSquare,
  faWarehouse,
  faUsers,
  faGlobeAmericas,
  faKey,
  faLongArrowAltUp,
  faInfoCircle,
  faDollarSign,
  faDonate,
  faPlus,
  faMinus,
  faTicketAlt,
  faAward,
  faAngleDoubleRight,
  faCheckDouble,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import {
  faDotCircle,
  faCircle,
  faSquare,
} from "@fortawesome/free-regular-svg-icons";

import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import highchartsMore from "highcharts/highcharts-more";
highchartsMore(Highcharts);

Vue.use(HighchartsVue, {
  highcharts: Highcharts,
});

library.add(
  faChartBar,
  faCalendarAlt,
  faCalendar,
  faDotCircle,
  faCircle,
  faAngleDoubleDown,
  faSort,
  faCaretUp,
  faCaretDown,
  faThLarge,
  faStar,
  faBasketballBall,
  faSign,
  faTrophy,
  faBookOpen,
  faHeadset,
  faFlag,
  faHandshake,
  faInstagramSquare,
  faLongArrowAltRight,
  faSquare,
  faEnvelopeOpenText,
  faSearch,
  faMagic,
  faChevronDown,
  faFacebookSquare,
  faExclamationTriangle,
  faTools,
  faCheck,
  faVolumeUp,
  faStream,
  faShareSquare,
  faWarehouse,
  faUsers,
  faGlobeAmericas,
  faKey,
  faLongArrowAltUp,
  faInfoCircle,
  faDollarSign,
  faDonate,
  faPlus,
  faMinus,
  faTicketAlt,
  faAward,
  faAngleDoubleRight,
  faCheckDouble,
  faPen
);

Vue.component("font-awesome-icon", FontAwesomeIcon); //使用kebab-case

Vue.component("svg-icon", SvgIcon);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA_ID },
  },
  router
);

const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
const req = require.context("@/icons", true, /\.svg$/);
requireAll(req);

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  const hasToken = getAccessToken();
  if (hasToken) {
    try {
      await store.dispatch("user/getInfo");

      if (
        !store.state.user.isPhoneVerified &&
        to.name !== "VerifyMobile" &&
        to.name !== "MemberCenter"
      ) {
        next({ name: "VerifyMobile" });
      } else {
        next();
      }
    } catch (error) {
      store.dispatch("user/logout");
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
