<template lang="pug">
v-dialog(
  v-model="dialogNormal",
  :scrollable="scroll",
  :max-width="maxWidth",
  persistent
)
  v-card
    v-card-title.flex-column.justify-center
      slot(name="title")
        .text-h5 {{ title }}
        v-icon.mt-6(x-large, color="#cccccc") {{ icon }}

    v-card-text.text-center.mt-3
      slot(name="content")
    v-card-actions
      v-spacer
      v-btn(outlined, color="dark-1", text="", @click="closeDialog") {{ btn }}
</template>

<script>
export default {
  name: "DialogNormal",
  props: {
    title: String,
    dialogNormal: Boolean,
    icon: String,
    scroll: Boolean,
    maxWidth: {
      default: 290,
      type: Number,
    },
    btn: {
      default: "OK",
      type: String,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeNormalDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 599px) {
  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
  }
}

::v-deep .v-dialog {
  width: 100%;
}
</style>