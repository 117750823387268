<template lang="pug">
.home(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-container.container--fluid.pa-0
    swiper.swiper-container(:options="swiperOption", ref="mySwiper")
      swiper-slide.d-flex.justify-content-between.swiper-slide(
        v-for="(carouselImage, index) in carouselImageList",
        :key="index",
        @click.native="bannerClick(carouselImage)"
      )
        img(:src="carouselImage.photo.image")
      .swiper-button-prev(slot="button-prev")
      .swiper-button-next(slot="button-next")
      .swiper-pagination(slot="pagination")
  v-container(v-if="false", style="margin-top: 100px")
    v-row.position-relative
      img.w-100(
        src="https://api.gamechanger.tw/media/gallery/photos/2a1cf4ee0f5c44f2b4acda402965d22b.jpg"
      )
      .stats.d-flex.justify-center.align-center
        .stat.league
          span.num 2
          span.type 聯盟
        .stat.team
          span.num 20
          span.type 隊伍
        .stat.player
          span.num 346
          span.type 球員
        .stat.game
          span.num 209
          span.type 比賽
  v-container.gcf_rank.text-center(v-if="false")
    .gcf_header.text-center
      svg-icon(iconClass="f_logo", className="f_logo")
      span 冠軍榜
    v-row
      v-col(cols="12", lg="6")
        v-row.justify-space-between.px-9(justify="center")
          v-col(cols="auto")
            h3.font-weight-bold
              img.mr-1(:src="crown")
              | 玩家總積分排行
          v-col(@click="toFantasy", cols="auto", style="cursor: pointer")
            span.font-weight-bold more
            svg-icon(iconClass="arrow_right", className="arrow_right")
        v-row.game_player.table_row.mt-0.px-9
          v-col.table.f_table.table_col.rounded
            table
              thead
                tr.bottom_head
                  th.bdl.bdr 排行
                  th.bdr 名字
                  th 總積分
              tbody
                tr(v-for="n in 5")
                  td.bdl.bdr(:class="getRankStyle(n)") {{ getRankText(n) }}
                  td.bdr value
                  td value
      v-col(cols="12", lg="6")
        v-row.justify-space-between.px-9(justify="center")
          v-col(cols="auto")
            h3.font-weight-bold
              img.mr-1(:src="crown")
              | 球員價值排行
          v-col(@click="toDraft", cols="auto", style="cursor: pointer")
            span.font-weight-bold more
            svg-icon(iconClass="arrow_right", className="arrow_right")
        v-row.basketball_player.table_row.mt-0.px-9
          v-col.table.f_table.table_col.rounded
            table
              thead
                tr.bottom_head
                  th.bdl.bdr 排行
                  th.bdr 球員
                  th.bdr 成長
                  th 價值
              tbody
                tr(v-for="n in 5")
                  td.bdl.bdr(:class="getRankStyle(n)") {{ getRankText(n) }}
                  td.bdr value
                  td.bdr value
                  td value
      v-col(cols="12", lg="6")
  v-container(v-show="currentNewsList.length > 0")
    v-row
      v-col 
        v-row
          v-col
            h2.main_title.mt-8 最新消息
              span News
    v-row.py-4.mt-5(
      justify="center",
      style="border-top: #d2d2d2 1px solid; border-bottom: #d2d2d2 1px solid"
    )
      v-col.justify-center(
        cols="12",
        v-for="(news, index) in currentNewsList",
        :key="index"
      )
        .news_item.d-flex.justify-center(@click="showNewsItemDetail(news)")
          span.date {{ news.date }}
          span.title {{ news.title }}
          .more_btn
            span more
            svg-icon(iconClass="arrow_right", className="arrow_right")
    v-row.py-4.mt-2
      v-col
        v-pagination(
          v-model="currentPage",
          :length="totalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
  v-container.container--fluid.mt-8(v-show="otherArticleList.length > 0", style="background: #f2f3f4")
    v-container.py-10
      v-row
        v-col
          h2.main_title.mt-0 專欄
            span Special Column
      v-row
        v-col(ols="12", md="6")
          v-responsive(:aspect-ratio="1 / 1")
            .special_item.lg.img.d-flex.justify-end.flex-column(
              style="cursor: pointer",
              @click="specialColumnClick(mainArticle)",
              v-if="mainArticle.photo",
              :style="{ backgroundImage: 'url(' + mainArticle.photo.image + ')' }"
            )
              .special_title {{ mainArticle.title }}
              .special_sub
                .league_tag.px-1
                  .text-overline.text_secondary {{ mainArticle.tag.name }}
                .date {{ mainArticle.date }}
        v-col.d-flex.flex-column.justify-space-between(cols="12", md="6")
          .special_item.sm.bg_white.d-flex(
            style="cursor: pointer",
            @click="specialColumnClick(article)",
            v-for="(article, index) in otherArticleList",
            :key="index"
          )
            v-responsive.flex-grow-0.flex-shrink-0(:aspect-ratio="1 / 1")
              .special_item_photo.img(
                :style="{ backgroundImage: 'url(' + article.photo.image + ')' }"
              )
            .special_item_content.d-flex.flex-grow-1.flex-column.flex-shrink-1.justify-space-between
              .special_title {{ article.title }}
              .special_sub
                .league_tag.px-1
                  .text-overline.text_secondary {{ article.tag.name }}
                .date {{ article.date }}
  custom-footer.mt-0
  dialog-normal(
    :title="currentNews.title",
    :scroll="true",
    @closeNormalDialog="closeNormalDialog",
    :dialogNormal="dialogNormalShow",
    :icon="null",
    :disabled="dialogNormalShow",
    :maxWidth="800",
    :btn="'Close'"
  )
    template(#content)
      .text-left
        span.font-weight-bold(
          style="text-align: left; text-decoration: underline"
        ) {{ currentNews.date }}
        p(v-html="currentNews.content")
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import crown from "@/assets/img/crown.png";
import CustomFooter from "@/components/common/Footer";
import DialogNormal from "@/components/dialog/DialogNormal";
import { getCarouselImage, getArticle, getNews } from "@/api/home";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    CustomFooter,
    StickyColumn,
    DialogNormal,
  },
  data() {
    return {
      currentNews: {
        title: null,
        content: null,
        date: null,
      },
      dialogNormalShow: false,
      carouselImageList: [],

      articleList: [],
      mainArticle: {},
      otherArticleList: [],

      newsList: [],
      currentNewsList: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 5,

      crown,
      swiperOption: {
        initialSlide: 0,
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        loop: false,
        direction: "horizontal",
        grabCursor: true,
        setWrapperSize: true,
        autoHeight: false,
        observeParents: true,
        allowSlideNext: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  computed: {
    sliderSwiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  watch: {
    currentPage() {
      this.getCurrentNewsList();
    },
  },
  created() {
    this.getCarouselImage();
    this.getArticle();
    this.getNews();
  },
  methods: {
    getCarouselImage() {
      getCarouselImage().then((response) => {
        this.carouselImageList = response.data;
      });
    },
    getNews() {
      getNews().then((response) => {
        this.newsList = response.data;
        this.getCurrentNewsList();
      });
    },
    getArticle() {
      getArticle().then((response) => {
        this.articleList = response.data;
        for (let index in this.articleList) {
          let temp = this.articleList[index];
          if (index == 0) {
            this.mainArticle = temp;
          } else {
            if (index < 4) {
              this.otherArticleList.push(temp);
            }
          }
        }
      });
    },
    getCurrentNewsList() {
      this.totalPages = Math.ceil(this.newsList.length / this.perPage);
      this.currentNewsList = [];
      let number = this.perPage * this.currentPage;
      this.currentNewsList = this.newsList.slice(number - this.perPage, number);
    },
    bannerClick(carouselImage) {
      if (carouselImage.link != "") {
        window.open(carouselImage.link);
      }
    },
    showNewsItemDetail(news) {
      this.currentNews = news;
      this.dialogNormalShow = true;
    },
    showNormalDialog() {
      this.dialogNormalShow = true;
    },
    closeNormalDialog() {
      this.dialogNormalShow = false;
    },
    specialColumnClick(article) {
      window.open(article.link);
    },
    getRankStyle(rank) {
      if (rank == 1) {
        return "gold";
      } else if (rank == 2) {
        return "sliver";
      } else if (rank == 3) {
        return "copper";
      } else {
        return "";
      }
    },
    getRankText(rank) {
      switch (rank) {
        case 1:
          return "1st";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        default:
          return rank + "th";
      }
    },
    toFantasy() {
      this.$router.push({ name: "Fantasy" });
    },
    toDraft() {
      this.$router.push({ name: "Draft" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/home/home";
</style>
