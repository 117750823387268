import request from "@/utils/request";

export function getCarouselImage() {
  return request({
    url: "/web_content/carousel_images/",
    method: "get",
  });
}

export function getArticle() {
  return request({
    url: "/web_content/articles/",
    method: "get",
  });
}

export function getNews() {
  return request({
    url: "/web_content/news/",
    method: "get",
  });
}
