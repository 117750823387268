<template lang="pug">
th.sticky(:style="{ left: offset + 'px' }", :class="{ 'hover' : hover}")
  slot {{ title }}
</template>

<script>
export default {
  name: "StickyColumn",
  props: {
    offset: Number,
    title: String,
    hover: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  // transform: translateX(-1px);
  // border-left: #dee2e6 1px solid;
}

.hover {
  &:hover {
    background-color: #fce8e9;
  }
}
</style>
