export default [
  {
    path: "/:league",
    name: "League",
    component: () => import("@/views/League/index.vue"),
    redirect: {
      name: "LeagueLeagueOverview",
    },
    children: [
      {
        path: "/:league/leagueoverview",
        name: "LeagueLeagueOverview",
        component: () => import("@/views/League/LeagueOverview/index"),
      },
      {
        path: "/:league/teamoverview",
        name: "LeagueTeamOverview",
        component: () => import("@/views/League/TeamOverview/index"),
      },
      {
        path: "/:league/playeroverview",
        name: "LeaguePlayerOverview",
        component: () => import("@/views/League/PlayerOverview/index"),
      },
    ],
  },
  {
    path: "/:league/team/:id",
    name: "LeagueTeam",
    component: () => import("@/views/League/Team/index.vue"),
    redirect: {
      name: "LeagueTeamRecords",
    },
    children: [
      {
        path: "/:league/team/:id/season/:season/records",
        name: "LeagueTeamRecords",
        component: () => import("@/views/League/Team/Records/index"),
      },
      {
        path: "/:league/team/:id/season/:season/statistics",
        name: "LeagueTeamStatistics",
        component: () => import("@/views/League/Team/Statistics/index"),
      },
      {
        path: "/:league/team/:id/season/:season/advancedstatistics",
        name: "LeagueTeamAdvancedStatistics",
        component: () => import("@/views/League/Team/AdvancedStatistics/index"),
      },
      {
        path: "/:league/team/:id/season/:season/lineup",
        name: "LeagueTeamLineUp",
        component: () => import("@/views/League/Team/LineUp/index"),
      },
    ],
  },
  {
    path: "/:league/player/:id",
    name: "LeaguePlayer",
    component: () => import("@/views/League/Player/index.vue"),
    redirect: {
      name: "LeaguePlayerGameLog",
    },
    children: [
      {
        path: "/:league/player/:id/season/:season/gamelog",
        name: "LeaguePlayerGameLog",
        component: () => import("@/views/League/Player/GameLog/index"),
      },
      {
        path: "/:league/player/:id/season/:season/statistic",
        name: "LeaguePlayerSeason",
        component: () => import("@/views/League/Player/Season/index"),
      },
      {
        path: "/:league/player/:id/job",
        name: "LeaguePlayerJob",
        component: () => import("@/views/League/Player/Job/index"),
      },
      {
        path: "/:league/player/:id/advanced",
        name: "LeaguePlayerAdvanced",
        component: () => import("@/views/League/Player/Advanced/index"),
      },
      {
        path: "/:league/player/:id/teameffect",
        name: "LeaguePlayerTeamEffect",
        component: () => import("@/views/League/Player/TeamEffect/index"),
      },
    ],
  },
  {
    path: "/:league/game/:id",
    name: "LeagueGame",
    component: () => import("@/views/League/Game/index.vue"),
    redirect: {
      name: "LeagueGameBoxScore",
    },
    children: [
      {
        path: "/:league/season/:season/game/:id/boxscore",
        name: "LeagueGameBoxScore",
        component: () => import("@/views/League/Game/BoxScore/index"),
      },
      {
        path: "/:league/game/:id/advancedstatistics",
        name: "LeagueGameAdvancedStatistics",
        component: () => import("@/views/League/Game/AdvancedStatistics/index"),
      },
    ],
  },
];
