<template lang="pug">
.nav
  .root.position-fixed.w-100(
    :height="$store.state.navbarHeight",
    style="z-index: 3",
    v-on:mouseleave="closeSubMenu"
  )
    v-container.pa-0.px-md-0
      v-app-bar.navbar.align-center(
        :elevation="0",
        :height="$store.state.navbarHeight",
        dense="",
        color="#002442"
      )
        .right_icons.d-flex.align-center
          v-app-bar-nav-icon.d-md-none.ml-3(
            @click="drawer = true",
            color="white"
          )

          v-toolbar-title.d-md-none.d-flex.justify-center.flex-grow-1.align-center(
            @click="toHome"
          )
            svg-icon(iconClass="gc_logo", className="gc_logo")
        .icons.d-flex.align-center.d-none.d-md-block(v-if="has_fantasy")
          svg-icon.fantasy_logo_border.d-block.d-md-none.mr-3(
            iconClass="f_logo",
            @click="toFantasyPage"
          )
          v-btn.d-md-none(icon="", small, v-if="false")
            v-icon(small, color="white") mdi-bell
          v-btn.d-md-none.mr-2(
            v-if="!$store.state.user.accessToken",
            @click="toLogin",
            icon="",
            small
          )
            v-icon(small, color="white") mdi-account
          .user_symbol.d-flex.d-md-none(
            v-if="$store.state.user.accessToken && !$store.state.user.photo",
            @click="toLogin"
          )
            img.user_avater.avater_sm(:src="gcAvatar")
          .user_symbol.d-flex.d-md-none(
            v-if="$store.state.user.accessToken && $store.state.user.photo",
            @click="toLogin"
          )
            img.user_avater.avater_sm(:src="$store.state.user.photo")

        v-tabs.d-none.d-md-block(
          dark,
          background-color="#002442",
          color="white",
          ripple="false",
          v-model="tabSelect",
          optional
        )
          v-tabs-slider(color="#E2252F")
          .left_menu.d-flex
            v-tab(
              @mouseover="openSubMenu(index)",
              @click="toPage(index)",
              :ripple="false",
              v-for="(league, index) in leagueList",
              :key="index"
            ) {{ league.name }}

            v-toolbar-title.d-flex.justify-center.align-center(v-if="!is_right_logo", @click="toHome")
              svg-icon(
                iconClass="gc_logo",
                className="gc_logo",
                style="width: 71px; height: 38px"
              )
          .left_menu.d-flex.align-center(v-if="is_right_logo")
            svg-icon.fantasy_logo_border.mr-10(
              iconClass="gc_logo",
              className="gc_logo",
              style="width: 71px; height: 38px",
              @click="toHome"
            )
          .left_menu.d-flex.align-center(v-if="has_fantasy")
            svg-icon.fantasy_logo_border.mr-10(
              iconClass="f_logo",
              @click="toFantasyPage"
            )
            v-btn(icon="", small, v-if="false")
              v-icon(small, color="white") mdi-bell
            v-btn(
              v-if="!$store.state.user.accessToken",
              @click="toLogin",
              icon="",
              small
            )
              v-icon(small, color="white") mdi-account
            .user_symbol.d-flex(
              v-if="$store.state.user.accessToken && !$store.state.user.photo",
              @click="toLogin"
            )
              img.user_avater.avater_sm(:src="gcAvatar")
            .user_symbol.d-flex(
              v-if="$store.state.user.accessToken && $store.state.user.photo",
              @click="toLogin"
            )
              img.user_avater.avater_sm(:src="$store.state.user.photo")
    v-container.pa-0
      v-expand-transition
        v-card.sub_menu.elevation-0(
          tile,
          color="rgba(246, 246, 246, 0.9)",
          v-for="(league, index) in leagueList",
          :key="index",
          v-if="hoveredTabIndex == index",
          :style="{ transform: 'translateX(' + 90 * hoveredTabIndex + 'px)' }"
        )
          .sub_menu_container
            li.item(
              :class="{ selected: $route.name == 'LeagueLeagueOverview' && $route.params.league == league.name }",
              @click="leagueClick(index)"
            ) 首頁
            li.item(
              :class="{ selected: $route.name == 'LeagueTeamOverview' && $route.params.league == league.name }",
              @click="teamClick(index)"
            ) 球隊
            li.item(
              :class="{ selected: $route.name == 'LeaguePlayerOverview' && $route.params.league == league.name }",
              @click="playerClick(index)"
            ) 球員
  v-navigation-drawer(v-model="drawer", fixed="", temporary="")
    v-expansion-panels(accordion)
      v-expansion-panel(
        :class="{ selected: tabSelect == i }",
        v-for="(league, i) in leagueList",
        :key="i"
      )
        v-expansion-panel-header
          | {{ league.name }}
          template(v-slot:actions="")
            v-icon(color="#ababab")
              | mdi-chevron-down

        v-expansion-panel-content
          .sub_menu_container
            li.item(
              :class="{ selected: tabSelect == i && $route.name == 'LeagueLeagueOverview' && $route.params.league == league.name }",
              @click="leagueClick(i)"
            ) 首頁
            li.item(
              :class="{ selected: tabSelect == i && $route.name == 'LeagueTeamOverview' && $route.params.league == league.name }",
              @click="teamClick(i)"
            ) 球隊
            li.item(
              :class="{ selected: tabSelect == i && $route.name == 'LeaguePlayerOverview' && $route.params.league == league.name }",
              @click="playerClick(i)"
            ) 球員

    button.v-expansion-panel-header(v-if="has_news", type="button", style="border: none")
      | 最新消息
      .v-expansion-panel-header__icon
        i.v-icon.notranslate.theme--light(
          style="color: rgb(171, 171, 171); caret-color: rgb(171, 171, 171)"
        )
</template>

<script>
import { getLeagueList } from "@/api/league";
import fLogoBorder from "@/assets/img/fantasy/f_logo_border.png";
import gcAvatar from "@/assets/img/gc-avatar.png";

export default {
  name: "Navbar",

  data() {
    return {
      gcAvatar,
      fLogoBorderImg: fLogoBorder,
      hoveredTabIndex: null,
      isLeagueMenuShow: false,
      isPleagueMenuShow: false,
      group: 0,
      drawer: false,
      oldTabSelect: -1,
      tabSelect: -1,
      rightTablist: [],
      leagueList: [],
      is_right_logo: process.env.VUE_APP_IS_RIGHT_LOGO
        ? JSON.parse(process.env.VUE_APP_IS_RIGHT_LOGO)
        : false,
      has_fantasy: process.env.VUE_APP_HAS_FANTASY
        ? JSON.parse(process.env.VUE_APP_HAS_FANTASY)
        : true,
      has_news: process.env.VUE_APP_HAS_NEWS
        ? JSON.parse(process.env.VUE_APP_HAS_NEWS)
        : true,
    };
  },
  watch: {
    tabSelect() {
      if (this.tabSelect == undefined) {
        this.$nextTick(() => {
          this.tabSelect = this.oldTabSelect;
        });
      } else {
        this.oldTabSelect = this.tabSelect;
      }
    },
    "$route.name"() {
      // this.checkTab();
    },
  },
  created() {
    this.getLeagueList();
  },
  methods: {
    getLeagueList() {
      getLeagueList().then((response) => {
        this.leagueList = response.data;
      });
    },
    getLeagueIndexByName(name) {
      let index = -1;
      for (let i in this.leagueList) {
        if (this.leagueList[i].name == name) {
          index = i;
        }
      }
      return index;
    },
    toLogin() {
      if (!this.$store.state.user.accessToken) {
        this.$router.push({ name: "Login" });
      } else {
        this.$router.push({ name: "MemberCenter" });
      }
    },
    toPage(index) {
      const league_name = this.leagueList[index].name;
      this.$router.push({ name: "League", params: { league: league_name } });
    },
    toHome() {
      this.$router.push({ name: "Home" });
      this.tabSelect = -1;
    },
    toFantasyPage() {
      this.$router.push({ name: "Fantasy" });
    },
    checkTab() {
      let index = this.getLeagueIndexByName(this.$route.params.league);
      if (index != -1) {
        this.$nextTick(() => {
          this.tabSelect = index;
        });
      } else {
        this.$nextTick(() => {
          this.tabSelect = -1;
        });
      }
    },
    leagueClick(index) {
      const league_name = this.leagueList[index].name;
      if (this.tabSelect != index) {
        this.tabSelect = index;
      }
      this.$router.push({
        name: "LeagueLeagueOverview",
        params: { league: league_name },
      });
    },
    teamClick(index) {
      const league_name = this.leagueList[index].name;
      if (this.tabSelect != index) {
        this.tabSelect = index;
      }
      this.$router.push({
        name: "LeagueTeamOverview",
        params: { league: league_name },
      });
    },
    playerClick(index) {
      const league_name = this.leagueList[index].name;
      if (this.tabSelect != index) {
        this.tabSelect = index;
      }
      this.$router.push({
        name: "LeaguePlayerOverview",
        params: { league: league_name },
      });
    },
    openSubMenu(index) {
      this.hoveredTabIndex = index;
      if (index == 0) {
        this.isLeagueMenuShow = true;
        this.isPleagueMenuShow = false;
      } else if (index == 1) {
        this.isPleagueMenuShow = true;
        this.isLeagueMenuShow = false;
      }
    },
    closeSubMenu() {
      this.hoveredTabIndex = null;
      this.isLeagueMenuShow = false;
      this.isPleagueMenuShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/navbar";
</style>
