import request from "@/utils/requestUser";
import store from "@/store";

export function register(data) {
  return request({
    url: "/user/",
    method: "post",
    data,
  });
}

export function login(data) {
  return request({
    url: "/user/login/",
    method: "post",
    data,
  });
}

export function getUserInfo() {
  return request({
    url: "/user/me/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function updateUserInfo(data) {
  return request({
    url: "/user/me/",
    method: "patch",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function setProfileCompelete() {
  return request({
    url: "/user/set_profile_complete/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function verifyEmail(data) {
  return request({
    url: "/user/activation/",
    method: "post",
    data,
  });
}

export function resetPassword(data) {
  return request({
    url: "/user/reset_password/",
    method: "post",
    data,
  });
}

export function resetPasswordConfirm(data) {
  return request({
    url: "/user/reset_password_confirm/",
    method: "post",
    data,
  });
}

export function uploadAvatar(data) {
  return request({
    url: "/user/upload_avatar/",
    method: "post",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
    data,
  });
}

export function getSexList() {
  return request({
    url: "/user/get_sex_list/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getCityList() {
  return request({
    url: "/user/get_city_list/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getDegreeList() {
  return request({
    url: "/user/get_degree_list/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getProfessionList() {
  return request({
    url: "/user/get_profession_list/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getMonthIncomeList() {
  return request({
    url: "/user/get_month_income_list/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getBasketballExperienceList() {
  return request({
    url: "/user/get_basketball_experience_list/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getContactFromList() {
  return request({
    url: "/user/get_contact_from_list/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function sendPhoneCode(data) {
  return request({
    url: "/user/send_phone_code/",
    method: "post",
    data,
  });
}

export function verifyPhoneCode(data) {
  return request({
    url: "/user/verify_phone_code/",
    method: "post",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
    data,
  });
}
