import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/module/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navbarHeight: 0,
    screenHeight: 0,
  },
  mutations: {
    setNavbarHeight(state, value) {
      state.navbarHeight = value;
    },
    setScreenHeight(state, value) {
      state.screenHeight = value;
    },
  },
  actions: {},
  modules: {
    user: user,
  },
});
